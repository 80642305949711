import "../App.css";
import BackgroundVideo from "./utils/BackgroundVideo";
import { Sash } from "./utils/Sash";
import { GoldButton } from "./utils/GoldButton";
import { ImageCarousel } from "./utils/ImageCarousel";
import { useNavigate, useLocation } from "react-router-dom";
import Link from "react-router-dom";
import { useState } from "react";

export default function Home() {
    const navigate = useNavigate();

    const [backgroundVideoloaded, setBackgroundVideoLoaded] = useState(false);

    const handleContactClick = async () => {
        // Navigate to "events/upcoming" first
        await navigate(`/contact`);
        window.scroll(0, 0);

        // Wait for a short duration before navigating to the specific event section
        setTimeout(() => {
            const eventSection = document.getElementById("contact-form");
            if (eventSection) {
                eventSection.scrollIntoView({ behavior: "smooth" });
            }
        }, 500);
    };

    return (
        <div
            className="bg-colour-dark"
            style={{
                transition: "opacity 0.5s ease-in-out",
                opacity: backgroundVideoloaded ? 1 : 0,
            }}
        >
            <BackgroundVideo onLoad={() => setBackgroundVideoLoaded(true)} />
            <div className="pb-[6%] bg-colour-dark">
                <div className="margin-centered bg-colour-dark midmd:columns-2 relative mt-8 md:mt-12 lg:mt-16 xl:mt-20 justify-self">
                    <div className="relative w-full bg-colour-dark">
                        <img
                            src="/assets/home/DSC00353.jpg"
                            className="responsive" // Use layout="responsive" for responsive images
                            width={1920} // Set the width of the image (replace with actual width)
                            height={1080} // Set the height of the image (replace with actual height)
                            alt="Yevshan"
                        />
                    </div>
                    <div className="bg-colour-dark text-black">
                        <h1 className=" text-3xl md:text-4xl lg:text-5xl xl:text-6xl font-bold pb-[5%] text-center">
                            60 Year History
                        </h1>
                        <p>
                            Founded in 1960, the Yevshan Ukrainian Folk Ballet
                            Ensemble has been a vibrant part of Canada&apos;s
                            cultural landscape for over six decades, making it
                            one of the nation’s longest-standing Ukrainian dance
                            groups. Born from a passion to preserve and
                            celebrate Ukrainian heritage through dance, Yevshan
                            has not only entertained audiences but also served
                            as a vital link to the past, bringing the rich
                            tapestry of Ukrainian folklore to life on stage.
                            With a repertoire that spans traditional dances from
                            various regions of Ukraine, each performance is a
                            colorful and dynamic tribute to the spirit and
                            enduring resilience of Ukrainian culture.
                            <br />
                            <br />
                            As we celebrate sixty years of artistic excellence,
                            Yevshan continues to inspire with its commitment to
                            innovation while respecting traditional roots. Our
                            dancers, from young enthusiasts to seasoned
                            performers, all share a deep love for Ukrainian
                            dance and a dedication to delivering breathtaking
                            performances. Join us as we continue our journey,
                            preserving this beautiful art form for future
                            generations to cherish and enjoy.{" "}
                        </p>
                    </div>
                </div>
            </div>
            <div className="bg-colour-light py-[4%] text-black">
                <div className="margin-centered midmd:columns-2 relative mt-8 justify-self">
                    <div className="">
                        <h1 className="text-3xl md:text-4xl lg:text-5xl xl:text-6xl font-bold pb-[5%] text-center">
                            World Wide Recognition
                        </h1>
                        <p>
                            Our impact extends far beyond the Canadian borders,
                            as we have showcased our extraordinary talent on
                            international stages. We have captivated audiences
                            across North America, Europe, and beyond, with tours
                            in the USA, Sweden, Chile, Ukraine, Cuba, and
                            Croatia. Each international appearance not only
                            displays our versatile dance prowess but also serves
                            as an ambassadorial role, promoting Ukrainian
                            culture worldwide through the universal language of
                            dance.
                            <br />
                            <br />
                            Perhaps most notably, we have had the distinct honor
                            of performing for the late Queen Elizabeth II on
                            three separate occasions. These performances are a
                            testament to our ensemble&apos;s excellence and the
                            high regard in which we are held on the global
                            stage. Through these prestigious engagements, we not
                            only represent Ukrainian heritage but also embody
                            the spirit of international friendship and cultural
                            exchange.
                        </p>
                    </div>
                    <div className="relative w-full ">
                        <img
                            src="/assets/home/IMG_5555.png"
                            className="responsive" // Use layout="responsive" for responsive images
                            width={1920} // Set the width of the image (replace with actual width)
                            height={1080} // Set the height of the image (replace with actual height)
                            alt="Yevshan"
                        />
                    </div>
                </div>
            </div>
            <div className="py-[4%] bg-colour-dark text-black">
                <div className="margin-centered midmd:columns-1 relative justify-self">
                    <h1 className="text-3xl md:text-4xl lg:text-5xl xl:text-6xl font-bold pb-[3%] text-center">
                        Life Long Memories
                    </h1>
                    <p className="pb-[3%]">
                        On our tours around the world, we do more than perform;
                        we create friendships that last a lifetime. Each show
                        brings us closer together as a group, turning us into a
                        family bonded by our love for Ukrainian dance. Off the
                        stage, the connections we make with people we
                        meet—audiences and artists alike—are just as special.
                        These relationships give us memories that stay with us
                        long after the applause fades.{" "}
                    </p>
                    <div className="relative w-full">
                        <div className="container mx-auto mt-8 md:px-[20%]">
                            <ImageCarousel imageDir={"/home/carousel"} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-colour-dark">
                <div className="midmd:columns-2 relative margin-centered justify-center pt-5 pb-10 flex-row flex space-x-10 items-center">
                    <GoldButton href="/joinus" text="Join Us" />
                    <span
                        className={`px-4 py-3 font-semibold shadow-md no-underline rounded-full bg-yevshan-gold hover:cursor-pointer text-black md:text-lg lg:text-xl border-blue btn-primary  hover:bg-[#b8b795] focus:outline-none active:shadow-none mr-2 whitespace-nowrap`}
                        onClick={() => handleContactClick()}
                    >
                        {`Performance Inquiry`}
                    </span>
                </div>
            </div>
            <Sash />
        </div>
    );
}
